import React from 'react';
import './App.css';
import { 
    // BrowserRouter, 
    Switch, 
    Route, 
    Redirect 
} from "react-router-dom";

import Woningen from './Components/Woningen';
import Industrie from './Components/Industrie';
import Visualisaties from './Components/Visualisaties';
import Contact from './Components/Contact';
import Detail from './Components/Detail';
import Editedgrid from './Components/Editedgrid';

function App() {
  return (
      <div className="App">
        <div className="container">       
            <Switch>
                <Route path="/Woningen">
                    <Woningen />
                </Route>
                <Route path="/Industrie">
                    <Industrie />
                </Route>
                <Route path="/Visualisaties">
                    <Visualisaties />
                </Route>
                <Route path="/Contact">
                    <Contact />
                </Route>            
                <Route path="/Detail/:cat/:id">
                    <Detail />
                </Route>          
                <Route exact path="/">
                    <Editedgrid />
                </Route>
                <Route path="*">
          <Redirect to="/" />
        </Route>
            </Switch>   
        </div>
      </div>
  );
}

export default App;