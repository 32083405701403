import React from 'react';
import './Header.css';
import { Fade as Hamburger } from 'hamburger-react'
import { Link, NavLink } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      logo: "logo_white_name.webp",
      classNameNavLink: "",
      burgerClosed: false,
      classMenu: "header__container--ul",
      colorBurger: "white" ,
      headerClass: " header hideBlack"
    }
    this.activeMenuItem=this.activeMenuItem.bind(this);
    this.handleBurger=this.handleBurger.bind(this); 
    this.handleScroll=this.handleScroll.bind(this); 
    this.handleResize = this.handleResize.bind(this);
  }
  
  activeMenuItem(event, id) {
    this.setState({ active: !this.state.active });
  }

  componentWillMount() {
    if( this.props.colorHeader == "black") {
      this.setState({ 
        logo: "logo_black_name.webp",
        colorBurger: "black" 
      })
    }
    else {
      this.setState ({ 
        logo: "logo_white_name.webp" ,
        colorBurger: "white" 
      })
    }
  }

  componentDidMount() {
    if (window.innerWidth < 1000) { this.setState( {headerClass: "header hideBlack"} )}
    // else { this.setState( {classMenu: "header__container--ul header__container--ul-hide"} )}
    if( this.props.colorHeader == "black") {
      this.setState({ 
        classNameNavLink: "header__container--li-black header__container--li", 
        logo: "logo_black_name.webp",
        colorBurger: "black" 
      })
    }
    else {    
      this.setState ({ 
        classNameNavLink: "header__container--li header__container--li-white", 
      })
    }    
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  }

  handleBurger () {
    if (this.state.burgerClosed) {
      this.setState ({
        burgerClosed: false,
        classMenu: "header__container--ul header__container--ul-hide",
        headerClass: "header hideBlack noPosFixedHeader",
      });
      if ( this.props.colorHeader == "black") this.setState({  logo: "logo_black_name.webp", colorBurger: "black" })
      else this.setState({  logo: "logo_white_name.webp" })
    } else {
      this.setState ({
        burgerClosed: true,
        classMenu: "header__container--ul header__container--ul-show",
        headerClass: "header showBlack posFixedHeader",
        logo: "logo_white_name.webp",
        colorBurger: "white"
      });
    }
  }

  handleResize = () => {
    if (window.innerWidth > 1080) {
      if( this.props.colorHeader == "black") this.setState({ logo: "logo_black_name.webp" })
      else this.setState({ logo: "logo_white_name.webp" })
    }
    else {
      if( this.state.burgerClosed) this.setState({ logo: "logo_white_name.webp" })
    }
  };

  handleScroll = () => {
    if (window.innerWidth > 1024) { this.setState( {headerClass: "header hideBlack"} )}
  };
  
    render() {
      return (
        <header className={this.state.headerClass}> 
          <section className="header__container">
              <Link to="/"><img src={process.env.PUBLIC_URL + "/Logo/" + this.state.logo} alt="Logo" className="header__container--logo"/></Link>            
              <Hamburger
                size={40}
                color={this.state.colorBurger} 
                onToggle={this.handleBurger}
                rounded
                toggled={this.state.burgerClosed}
                className="header__hamburger"
              />
              <ul className={this.state.classMenu}>
                  <li><NavLink className={this.state.classNameNavLink} activeClassName="header__container--active-li" exact to="/">Home</NavLink></li>
                  <li><NavLink className={this.state.classNameNavLink} activeClassName="header__container--active-li" to="/Woningen">Woningen</NavLink></li>
                  <li><NavLink className={this.state.classNameNavLink} activeClassName="header__container--active-li" to="/Industrie">Industrie</NavLink></li>
                  <li><NavLink className={this.state.classNameNavLink} activeClassName="header__container--active-li" to="/Visualisaties">3D Visualisaties</NavLink></li>
                  <li className="lineContact"><p><NavLink className={this.state.classNameNavLink} activeClassName="header__container--active-li" to="/Contact">Contact</NavLink></p></li>
                  <li className="line"><p>Made By <a href="https://www.instagram.com/studiolam.design/">STUDIO LAM.</a></p></li>
              </ul>
          </section>
        </header>
      );
    }
  }

export default Header;