import React, {Suspense} from 'react';
import './Woningen.css';
import { Link } from "react-router-dom";
import firebase from '../firebase';

const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));
const Helmet = React.lazy(() => import('react-helmet'));
const Gallery = React.lazy(() => import('react-grid-gallery'));

class Woningen extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      houses: [],
      renderedHouses: [],
      colorHeader: "black",
      showContactForm: false,
			showFooterInfo: true,
      showFooterCopyright: true,
      allEntries: []
    }
    this.handleFirebase = this.handleFirebase.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.getInfo = this.getInfo.bind(this);
  }

  componentDidMount() {
    this.handleFirebase();
  }

  handleFirebase() {    
      firebase.database().ref("BrigitteCoomans/Woningen").orderByKey().on("value", snapshot => {
      let tempArray = [];
      let allEntries = [];
      
      snapshot.forEach(snap => {
          tempArray.src = "./Houses/Woningen/" + snap.val().Img.split(",")[0] + ".webp";
          tempArray.thumbnail = "./Houses/Woningen/Thumbnail/" + snap.val().Img.split(",")[0] + ".webp";
          allEntries.push(
              tempArray
          );
          tempArray = [];
      });
      this.setState({ houses: allEntries });
    });
  }

  getInfo(index) {
    let temp = "";
    firebase.database().ref("BrigitteCoomans/Woningen").child(index).on("value", snapshot => {
      console.log(snapshot.val().exp.split(' ').join('%20'));
       temp = snapshot.val().exp.split(' ').join('%20');
      });
    return temp;
  }

  handleImageClick (index, event) {
    window.location.href = process.env.PUBLIC_URL + "/Detail/Woningen/" + index + "/" + this.getInfo(index);
  }

    render() {
      return (
        <div className="woningen">
        <Suspense fallback={<div>Loading...</div>}>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Architectenbureau Dedeyne-Coomans -- Woningen</title>
              <meta name="description" content="Nieuwbouw en meergezinswoningen doorheen Vlaanderen" />
              <meta name="keywords" content="Nieuwbouw, Verbouwingen, Woningen, Brigitte Coomans, brigitte, Coomans, architect, torhout, brugge, blankenberge, koksijde, west vlaanderen, vlaanderen, jean coomans, luc coomans, Ieper, jules coomans, studio lam, studio, lam, bruno dedeyne"/>
              <meta name="author" content="Brigitte Coomans"/>
          </Helmet>
          <Header colorHeader={this.state.colorHeader}/>
          <div className="woningen__cover-image--container">
            <div className="woningen__title" >
              <p>
                Woningen
              </p>
            </div>
            <div className="woningen__under-title" >
              <p>
                Nieuwbouw woningen, verbouwingen, meergezinswoningen, ... doorheen Vlaanderen.
              </p>
            </div>
          </div>
          <div className="woningen__container">
           <Gallery 
              images={this.state.houses}
              enableLightbox={false}
              enableImageSelection={true}
              onClickThumbnail={(event, index) => this.handleImageClick(event, index)}
              className="Gallery_Houses"
            />
          </div>
          <Footer showContactForm={this.state.showContactForm} showFooterCopyright={this.state.showFooterCopyright} showFooterInfo={this.state.showFooterInfo} />    	
          </Suspense>
        </div>
      );
    }
  }

export default Woningen;