import React from 'react';
import './Contact.css';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import Header from './Header';
import Footer from './Footer';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';

import {Helmet} from "react-helmet";

// import Recaptcha from 'react-recaptcha';

class Contact extends React.Component {
  	constructor(props) {
      super(props);
      this.state = {
        name: "",
        email: "",
        message: "",
        phone: "",
        errorName: "",
        errorEmail: "",
        errorMessage: "",
        errorPhone: "",
        colorHeader: "black",
        showContactForm: false,
        showFooterInfo: false,
        showFooterCopyright: true,   
        footerCopyrightPositionAb: true,   
        className: "contact__contact-form--textfield-name",
        classPhone: "contact__contact-form--textfield-phone",
        classEmail: "contact__contact-form--textfield-email",
        classMessage: "contact__contact-form--textfield-message",
        classInfoBox: "contact__color-field",
        classContact__map: "contact__map",
        classContact:"contact",
        snackbarMessage: "",
        open: false,
        snackBarReopenInfoBox: false,
        openInfoBox: true
        // enabled: true
      }
        this.handleName = this.handleName.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseInfoBox = this.handleCloseInfoBox.bind(this);
        this.handleOpenInfoBox = this.handleOpenInfoBox.bind(this);

        // this.verifyCallback = this.verifyCallback.bind(this);
        // this.callback = this.callback.bind(this);

        // this.onResolved = this.onResolved.bind(this);
    }
    // onResolved = (event) => {
    //   console.log(event);
    // };

    //     callback() {
    //   console.log('Done!!!!');
    // };

    //   verifyCallback (response) {
    //     if (response) {
    //       this.setState ({
    //         enabled: false
    //       })
    //     }
    //   };

    handleSubmit(e) {
        if ( this.state.name === "") {
            e.preventDefault()
            this.setState({
                errorName: "Gelieve een naam in te voeren!",
                className: "contact__contact-form--textfield-name contact__contact-form--textfield-error"
            })
        }
        if ( this.state.phone === "") {
            e.preventDefault()
            this.setState({
                errorPhone: "Gelieve een telefoon in te voeren!",
                classPhone: "contact__contact-form--textfield-phone contact__contact-form--textfield-error"
            })
        } 
        if ( this.state.email === "") {
            e.preventDefault()
            this.setState({
                errorEmail: "Gelieve een email in te voeren!",
                classEmail: "contact__contact-form--textfield-email contact__contact-form--textfield-error"
            })
        }
        if ( this.state.message === "") {
            e.preventDefault()
            this.setState({
                errorMessage: "Gelieve een bericht in te voeren!",
                classMessage: "contact__contact-form--textfield-message contact__contact-form--textfield-error"
            })
        } 
        if ( this.state.name !== "" && this.state.phone !== "" && this.state.email !== "" && this.state.message !== "") {
          const templateId = 'template_hzeo1w8';
          this.sendFeedback(templateId, {from_name: this.state.name, from_phone: this.state.phone, from_email: this.state.email, message_html: this.state.message})
        } 
    }

  sendFeedback (templateId, variables) {
    window.emailjs.send(
      "service_nbofc6i", 
      templateId,
      variables
      ).then(res => {
        this.setState({
          open: true,
          snackbarMessage: "Email is verzonden!",
          name: "",
          email: "",
          phone: "",
          message: ""
        })
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => this.setState({ open: true, snackbarMessage: "Er ging iets mis. Probeer later opnieuw!" }))
    }

    handleName(e) {
        this.setState({     
            name: e.target.value,
            errorName: "",
            className: "contact__contact-form--textfield-name"
        });
    };

    handlePhone(e) {
        this.setState({     
            phone: e.target.value,
            errorPhone: "",
            classPhone: "contact__contact-form--textfield-phone"            
        });
    };

    handleEmail(e) {
        this.setState({ 
            email: e.target.value ,
            errorEmail: "",
            classEmail: "contact__contact-form--textfield-email"
        });
    };
  

    handleMessage(e) {
        this.setState({ 
            message: e.target.value ,
            errorMessage: "",
            classMessage: "contact__contact-form--textfield-message"
        });
    };

    handleClose (event, reason) {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({
        open: false,
      });
    };

    handleCloseInfoBox (event, reason) {
      this.setState({
        classInfoBox: "contact__color-field contact__color-field-hideInfoBox",
        classContact__map: "contact__map contact__map--opacity-full",
        classContact: "contact__info-box--hide",
        snackBarReopenInfoBox: true
      });
    };

    handleOpenInfoBox (event, reason) {
      this.setState({
        classInfoBox: "contact__color-field",
        classContact__map: "contact__map",
        classContact: "contact",
        snackBarReopenInfoBox: false
      });
    };

    render() {
      return (
        <div className={this.state.classContact}>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Architectenbureau Dedeyne-Coomans -- Contact</title>
              <meta name="description" content="Contacteer Architectenbureau Dedeyne - Coomans" />
              <meta name="keywords" content="contact, Nieuwbouw, Verbouwingen, Woningen, Visualisaties, industrie Brigitte Coomans, brigitte, Coomans, architect, torhout, brugge, blankenberge, koksijde, west vlaanderen, vlaanderen, jean coomans, luc coomans, Ieper, jules coomans, studio lam, studio, lam, bruno dedeyne"/>
              <meta name="author" content="Brigitte Coomans"/>
          </Helmet>
          <Header colorHeader={this.state.colorHeader} />
          <div className="contact__container">
            <Map
              className={this.state.classContact__map}
              google={this.props.google}
              zoom={11}
              initialCenter={{ lat: 51.014133, lng: 3.106760}}
              zoomControl= {true}
              mapTypeControl= {false}
              scaleControl= {false}
              streetViewControl= {true}
              rotateControl= {false}
              fullscreenControl= {false}
            >
              <Marker position={{ lat: 51.081570, lng: 3.106760}} />
            </Map>
          </div>
          <div className={this.state.classInfoBox}>
            <div className="contact__color-field-green">
             <React.Fragment>
                <IconButton className="contact__color-field-green--close-button" size="small" aria-label="close" color="inherit" onClick={this.handleCloseInfoBox}>
                  <CloseIcon className="contact__color-field-green--sign-close" fontSize="small" />
                </IconButton>
              </React.Fragment>
              <h4>Contact Informatie</h4>
              <div className="contact__color-field-green--signs">
                <HomeOutlinedIcon className="contact__color-field-green--home-icon"/>
                <CallOutlinedIcon className="contact__color-field-green--call-icon"/>
                <MailOutlineOutlinedIcon className="contact__color-field-green--mail-icon"/>
                <BusinessCenterOutlinedIcon className="contact__color-field-green--business-icon"/>
              </div>
              <div className="contact__color-field-green--text">
                <p>Oude Gentweg 26a<br/>
                8820 Torhout</p>
                <p>050 / 21 . 63 . 55</p>
                <p>dedeyne.coomans@scarlet.be</p>
                <p>BTW BE 860 . 425 . 434</p>
              </div>
            </div>
            <div className="contact__color-field-white">
              <div className="contact__contact-form--div">     
                <TextField 
                  required 
                  id="standard-required" 
                  label="Naam" 
                  className={this.state.className} 
                  onChange={event => this.handleName(event)}
                  value={this.state.name}
                  helperText={this.state.errorName}
                />
                <TextField 
                  required 
                  id="standard-required" 
                  label="Telefoon" 
                  type="phone" 
                  className={this.state.classPhone} 
                  onChange={event => this.handlePhone(event)}
                  value={this.state.phone}
                  helperText={this.state.errorPhone}
                /> <br/>
                <TextField 
                  required 
                  id="standard-required" 
                  label="Email" 
                  type="mail" 
                  className={this.state.classEmail} 
                  onChange={event => this.handleEmail(event)}
                  value={this.state.email}
                  helperText={this.state.errorEmail}
                /><br/>
                <TextField 
                  required 
                  id="standard-required" 
                  label="Bericht" 
                  multiline 
                  className={this.state.classMessage} 
                  rowsMax={3}
                  onChange={event => this.handleMessage(event)}
                  value={this.state.message}
                  helperText={this.state.errorMessage}
                />
                <Button variant="contained" color="primary" className="contact__contact-form--submit-btn" disabled={this.state.enabled} onClick={event => this.handleSubmit(event)}>
                  Verzenden
                </Button>
              </div>
            </div>
          </div>
          <Footer className="footer_contact" showContactForm={this.state.showContactForm} showFooterCopyright={this.state.showFooterCopyright} showFooterInfo={this.state.showFooterInfo} footerCopyrightPositionAb={this.state.footerCopyrightPositionAb}/>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            autoHideDuration={5000}
            onClose={this.handleClose}
            message={this.state.snackbarMessage}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.snackBarReopenInfoBox}
            onClose={this.handleClose}
            className="contact__snackbar-info-box--reopen"
            action={
              <React.Fragment>
                <Button className="contact__snackbar-info-box--text" color="secondary" size="small" onClick={this.handleOpenInfoBox}>
                  HEROPEN
                </Button>
              </React.Fragment>
            }
          />
           {/* <Recaptcha
                    sitekey="6LdUKRIaAAAAABdqh9d2Mq3woj-Senav5PnhUl-o"
                    render="explicit"
                    size="invisible"
                    verifyCallback={this.verifyCallback}
                    onloadCallback={this.recaptchaLoaded}
                /> */}
        </div>
      );
    }
  }

export default GoogleApiWrapper({
    apiKey: 'AIzaSyA_aS8VQaZ3iFuqxRkiSQDBB0gTezXYWQg'
})(Contact);