import React, {Suspense} from 'react';
import './Editedgrid.css';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";

const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));
const Home = React.lazy(() => import('./Home'));
const Helmet = React.lazy(() => import('react-helmet'));

class Editedgrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			colorHeader: "white",
			showContactForm: true,
			showFooterInfo: true,
        	showFooterCopyright: true,    
			classPhotoGrid: "photo-grid",
			scrolled: false
		}
		this.handleScroll=this.handleScroll.bind(this); 
	}

	componentDidMount() {
     	window.addEventListener("scroll", this.handleScroll);
	}

	handleScroll = () => {
		if (window.scrollY < 200 & !this.state.scrolled) this.setState ({ classPhotoGrid: "photo-grid hidePaperbox" })
		else this.setState ({ classPhotoGrid: "photo-grid addAnimation", scrolled: true })
	};

    render() {
      return (
		<div>
		<Suspense fallback={<div>Loading...</div>}>
		<Helmet>
              <meta charSet="utf-8" />
              <title>Architectenbureau Dedeyne-Coomans</title>
              <meta name="description" content="Architectenbureau Dedeyne - Coomans, Torhout" />
              <meta name="keywords" content="Nieuwbouw, Verbouwingen, Woningen, Visualisaties, industrie Brigitte Coomans, brigitte, Coomans, architect, torhout, brugge, blankenberge, koksijde, west vlaanderen, vlaanderen, jean coomans, luc coomans, Ieper, jules coomans, studio lam, studio, lam, bruno dedeyne"/>
              <meta name="author" content="Brigitte Coomans"/>
          </Helmet>
			<Header colorHeader={this.state.colorHeader} />
			<Home />
			<section className={this.state.classPhotoGrid} id="moreinfo">
				<Grid container spacing={3}>
						<Grid item xs>
							<Link to="/Visualisaties">
								<Paper className="photo-grid__paper-box">
									<div className="photo-grid__paper-box--image">
										<img src="./Houses/Visualisaties/Thumbnail/1c_cover.webp" alt="coverVisualisaties" className="photo-grid__paper-box--xs"/>
									</div>
									<p className="photo-grid__paper-box--house-category">3D Visualisatie</p>
									<p className="photo-grid__paper-box--house-explenation">Appartement, Blankenberge</p>
								</Paper>
							</Link>
						</Grid>				
						<Grid item xs>
							<Link to="/Industrie">
								<Paper className="photo-grid__paper-box">
									<div className="photo-grid__paper-box--image">
										<img src="./Houses/Industrie/Thumbnail/2b.webp" alt="coverWoningen" className="photo-grid__paper-box--xs"/>
									</div>
									<p className="photo-grid__paper-box--house-category">Industrie</p>
									<p className="photo-grid__paper-box--house-explenation">Bedrijfsverzamelgebouw, Blankenberge</p>
								</Paper>
							</Link>
						</Grid>	
						<Grid item xs>
							<Link to="/Woningen">
								<Paper className="photo-grid__paper-box">
									<div className="photo-grid__paper-box--image">
										<img src="./Houses/Woningen/Thumbnail/4a.webp" alt="coverNieuwbouw" className="photo-grid__paper-box--xs"/>
									</div>
									<p className="photo-grid__paper-box--house-category">Nieuwbouw</p>
									<p className="photo-grid__paper-box--house-explenation">Woning, Torhout</p>
								</Paper>
							</Link>
						</Grid>	 
				</Grid>
				
			</section>	
			<Footer showContactForm={this.state.showContactForm} showFooterCopyright={this.state.showFooterCopyright} showFooterInfo={this.state.showFooterInfo} />    	
            </Suspense>
		</div>
      );
    }
  }

export default Editedgrid;