import React, {Suspense} from 'react';
import './Industrie.css';
import firebase from '../firebase';
import { Link } from "react-router-dom";

const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));
const StackGrid = React.lazy(() => import('react-stack-grid'));
const Helmet = React.lazy(() => import('react-helmet'));
const Gallery = React.lazy(() => import('react-grid-gallery'));

class Industrie extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      houses: [],
      colorHeader: "black",
      showContactForm: false,
			showFooterInfo: true,
      showFooterCopyright: true,    
      classPhotoGrid: "industrie__grid",  
    }
    this.handleFirebase = this.handleFirebase.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.getInfo = this.getInfo.bind(this);
  }
  
  componentDidMount() {
    this.handleFirebase();
  }

  handleFirebase() {    
      firebase.database().ref("BrigitteCoomans/Industrie").orderByKey().on("value", snapshot => {
      let tempArray = [];
      let allEntries = [];
      snapshot.forEach(snap => {
          tempArray.src = "./Houses/Industrie/" + snap.val().Img.split(",")[0] + ".webp";
          tempArray.thumbnail = "./Houses/Industrie/Thumbnail/" + snap.val().Img.split(",")[0] + ".webp";
          allEntries.push(
              tempArray
          );
          tempArray = [];
      });
      this.setState({ houses: allEntries });
    });
  }

  getInfo(index) {
    let temp = "";
    firebase.database().ref("BrigitteCoomans/Industrie").child(index).on("value", snapshot => {
       temp = snapshot.val().exp.split(' ').join('%20');
      });
    return temp;
  }

  handleImageClick (index, event) {
    window.location.href = process.env.PUBLIC_URL + "/Detail/Industrie/" + index + "/" + this.getInfo(index);
  }

    render() {
      return (
        <div className="industrie">
        <Suspense fallback={<div>Loading...</div>}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Architectenbureau Dedeyne-Coomans -- Industrie</title>
              <meta name="description" content="Woningen en handelspanden" />
              <meta name="keywords" content="Industrie, Woningen, Brigitte Coomans, brigitte, Coomans, architect, torhout, brugge, blankenberge, koksijde, west vlaanderen, vlaanderen, jean coomans, luc coomans, Ieper, jules coomans, studio lam, studio, lam, bruno dedeyne"/>
              <meta name="author" content="Brigitte Coomans"/>
          </Helmet>
          <Header colorHeader={this.state.colorHeader}/>

          <div className="industrie__cover-image--container">
           <div className="visualisaties__title" >
              <p>
                Industrie
              </p>
            </div>
            <div className="visualisaties__under-title" >
              <p>
                Woningen en handelspanden.
              </p>
            </div></div>
          <div className="industrie__container">
            <Gallery 
              images={this.state.houses}
              enableLightbox={false}
              enableImageSelection={true}
              onClickThumbnail={(event, index) => this.handleImageClick(event, index)}
              className="Gallery_Houses"
            />
          </div>
          <Footer showContactForm={this.state.showContactForm} showFooterCopyright={this.state.showFooterCopyright} showFooterInfo={this.state.showFooterInfo} />    	
        </Suspense>
        </div>
      );
    }
  }

export default Industrie;