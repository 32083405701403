import React, {Suspense} from 'react';
import './Detail.css';
import firebase from '../firebase';

const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));
const Gallery = React.lazy(() => import('react-grid-gallery'));
const Helmet = React.lazy(() => import('react-helmet'));

class Detail extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            colorHeader: "black",
            showContactForm: false,
            showFooterInfo: true,
            showFooterCopyright: true,
            footerCopyrightPositionAb: true,   
            src: "",
            cat: "",
            images: [],
            urlId: 0,
            urlCatergory: "",
            classTiles: "detail__gallery"
        }

        this.getWoning = this.getWoning.bind(this);
    }

    async componentDidMount() {
        await this.setState ({
            urlId: window.location.pathname.split("/")[3],
            urlCatergory: window.location.pathname.split("/")[2]
        });
        this.getWoning();
    }



    getWoning () {   
        firebase.database().ref("BrigitteCoomans/" + this.state.urlCatergory + "/" + this.state.urlId).orderByKey().on("value", snapshot => {
            this.setState({
                cat: snapshot.val().cat,
                exp: snapshot.val().exp
            })
            let tempArray = [];
            let allEntries = [];
            if (snapshot.val().Img.split(",").length == 3) this.setState ({classTiles: "detail__gallery--3childs detail__gallery"})
            else if (snapshot.val().Img.split(",").length == 1) this.setState ({classTiles: "detail__gallery--1child detail__gallery"})
            snapshot.val().Img.split(",").forEach(snap => {
              
                    tempArray.thumbnail = "/Houses/" + this.state.urlCatergory + "/" + snap + ".webp";
                    tempArray.src = "/Houses/" + this.state.urlCatergory + "/" + snap + ".webp";
                allEntries.push(
                    tempArray
                );
                tempArray = [];
            });
            this.setState({ images: allEntries, detailCat: this.state.urlCatergory });
    });
    }

    render() {
      return (
        <div className="detail">
         <Suspense fallback={<div>Loading...</div>}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Architectenbureau Dedeyne-Coomans</title>
              <meta name="description" content="Architectenbureau Dedeyne - Coomans, Torhout" />
              <meta name="keywords" content="Nieuwbouw, Verbouwingen, Woningen, Visualisaties, industrie Brigitte Coomans, brigitte, Coomans, architect, torhout, brugge, blankenberge, koksijde, west vlaanderen, vlaanderen, jean coomans, luc coomans, Ieper, jules coomans, studio lam, studio, lam, bruno dedeyne"/>
              <meta name="author" content="Brigitte Coomans"/>
            </Helmet>
            <Header colorHeader={this.state.colorHeader}/> 
            <div className="detail__container">      
                <div className="detail__titles" >
                    <p className="detail__house-category">{this.state.cat}</p>
                    <p className="detail__house-explenation">{this.state.exp}</p>  
                </div>
                <div className={this.state.classTiles}>
                    <Gallery 
                        images={this.state.images}
                        enableLightbox={true}
                        enableImageSelection={true}
                        className="reactGrid_Gallery"
                    />
                </div>
            </div>
            <div className="footer_detail">
            <Footer className="footer_detail" showContactForm={this.state.showContactForm} showFooterCopyright={this.state.showFooterCopyright} showFooterInfo={this.state.showFooterInfo} footerCopyrightPositionAb={this.state.footerCopyrightPositionAb}/>
            </div>
            </Suspense>
        </div>
      );
    }
  }

export default Detail;