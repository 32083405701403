import React from 'react';
import './Footer.css';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// import React, {Suspense} from 'react';
// import './Footer.css';

// const CloseIcon = React.lazy(() => import('@material-ui/icons/Close'));
// const TextField = React.lazy(() => import('@material-ui/core/TextField'));
// const Button = React.lazy(() => import('@material-ui/core/Button'));
// const Snackbar = React.lazy(() => import('@material-ui/core/Snackbar'));
// const IconButton = React.lazy(() => import('@material-ui/core/Iconbutton'));

class Footer extends React.Component {
   constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            phone: "",
            errorName: "",
            errorEmail: "",
            errorMessage: "",
            errorPhone: "",
            className: "footer__contact-form--textfield-name",
            classPhone: "footer__contact-form--textfield-phone",
            classEmail: "footer__contact-form--textfield-email",
            classMessage: "footer__contact-form--textfield-message",
            classShowContactForm: "",
            classShowFooterCopyright: "",
            classShowFooterInfo: "",
            classFooterPositionAb: "",
            snackbarMessage: "",
            open: false,
            yearNow: ""
        };
        this.handleName = this.handleName.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
      if( this.props.showContactForm ) this.setState({ classShowContactForm: "footer__contact-form footer__contact--show"})
      else this.setState({ classShowContactForm: "footer__contact-form footer__contact--hide"})

      if( this.props.showFooterCopyright ) this.setState({ classShowFooterCopyright: "footer__copyright footer__copyright--show"})
      else this.setState({ classShowFooterCopyright: "footer__copyright footer__copyright--hide"})

      if( this.props.showFooterInfo ) this.setState({ classShowFooterInfo: "footer__info-architect footer__info--show"})
      else this.setState({ classShowFooterInfo: "footer__info-architect footer__info--hide"})

      if( this.props.footerCopyrightPositionAb ) this.setState({ classFooterPositionAb: "footer footer__copyright--position-ab"})
      else this.setState({ classFooterPositionAb: "footer"})

      this.setState({yearNow: new Date().getFullYear()})
    }

    handleSubmit(e) {
        if ( this.state.name === "") {
            e.preventDefault()
            this.setState({
                errorName: "Gelieve een naam in te voeren!",
                className: "footer__contact-form--textfield-name footer__contact-form--textfield-error"
            })
        }
        if ( this.state.phone === "") {
            e.preventDefault()
            this.setState({
                errorPhone: "Gelieve een telefoon in te voeren!",
                classPhone: "footer__contact-form--textfield-phone footer__contact-form--textfield-error"
            })
        } 
        if ( this.state.email === "") {
            e.preventDefault()
            this.setState({
                errorEmail: "Gelieve een email in te voeren!",
                classEmail: "footer__contact-form--textfield-email footer__contact-form--textfield-error"
            })
        }
        if ( this.state.message === "") {
            e.preventDefault()
            this.setState({
                errorMessage: "Gelieve een bericht in te voeren!",
                classMessage: "footer__contact-form--textfield-message footer__contact-form--textfield-error"
            })
        } 
        if ( this.state.name !== "" && this.state.phone !== "" && this.state.email !== "" && this.state.message !== "") {
          const templateId = 'template_hzeo1w8';
          this.sendFeedback(templateId, {from_name: this.state.name, from_phone: this.state.phone, from_email: this.state.email, message_html: this.state.message})
        } 
    }

  sendFeedback (templateId, variables) {
    window.emailjs.send(
      "service_nbofc6i", 
      templateId,
      variables
      ).then(res => {

        this.setState({
          open: true,
          snackbarMessage: "Email is verzonden!",
          name: "",
          email: "",
          phone: "",
          message: ""
        })
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => this.setState({ open: true, snackbarMessage: "Er ging iets mis. Probeer later opnieuw!" }))
    }

    handleName(e) {
        this.setState({     
            name: e.target.value,
            errorName: "",
            className: "footer__contact-form--textfield-name"
        });
    };

    handlePhone(e) {
        this.setState({     
            phone: e.target.value,
            errorPhone: "",
            classPhone: "footer__contact-form--textfield-phone"            
        });
    };

    handleEmail(e) {
        this.setState({ 
            email: e.target.value ,
            errorEmail: "",
            classEmail: "footer__contact-form--textfield-email"
        });
    };
  

    handleMessage(e) {
        this.setState({ 
            message: e.target.value ,
            errorMessage: "",
            classMessage: "footer__contact-form--textfield-message"
        });
    };

    handleClose (event, reason) {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({
        open: false,
      });
    };

    
    render() {
      return (
        <div className={this.state.classFooterPositionAb}>
          <footer className={this.state.classShowContactForm}>   
            <div className="footer__contact-form--div">     
              <TextField 
                required 
                id="standard-required" 
                label="Naam" 
                className={this.state.className} 
                onChange={event => this.handleName(event)}
                value={this.state.name}
                helperText={this.state.errorName}
              />
              <TextField 
                required 
                id="standard-required" 
                label="Telefoon" 
                type="phone" 
                className={this.state.classPhone} 
                onChange={event => this.handlePhone(event)}
                value={this.state.phone}
                helperText={this.state.errorPhone}
              /> <br/>
              <TextField 
                required 
                id="standard-required" 
                label="Email" 
                type="mail" 
                className={this.state.classEmail} 
                onChange={event => this.handleEmail(event)}
                value={this.state.email}
                helperText={this.state.errorEmail}
              /><br/>
              <TextField 
                required 
                id="standard-required" 
                label="Bericht" 
                multiline 
                className={this.state.classMessage} 
                rowsMax={5}
                onChange={event => this.handleMessage(event)}
                value={this.state.message}
                helperText={this.state.errorMessage}
              />
              <Button variant="contained" color="primary" className="footer__contact-form--submit-btn" onClick={event => this.handleSubmit(event)}>
                Verzenden
              </Button>
            </div>
          </footer>
          <footer className={this.state.classShowFooterInfo}>
            <div><img src={process.env.PUBLIC_URL + "/Logo/logo_white_name.webp"} alt="Logo White" className="footer__info-architect--logo"/></div>
            <div> 
              <p className="footer__info-architect--address">Brigitte Coomans<br/>Oude Gentweg 26a <br/>8820 Torhout</p>
              <p className="footer__info-architect--info" >BTW: BE 860.425.434<br/>Tel: 050/21.63.55<br/>dedeyne.coomans@scarlet.be</p>
            </div>
          </footer>
          <footer className={this.state.classShowFooterCopyright}>
            <p>© {this.state.yearNow}. Made with 	&#9829; by  <a href="https://www.instagram.com/studiolam.design/">STUDIO LAM.</a></p>
          </footer>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            autoHideDuration={5000}
            onClose={this.handleClose}
            message={this.state.snackbarMessage}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>
      );
    }
  }

export default Footer;